<template>
    <v-dialog v-model="dialog" persistent max-width="500">
        <v-card>
            <v-card-title v-if="dialogTitle">{{ dialogTitle }}</v-card-title>
            <v-card-text v-if="dialogText">{{ dialogText }}</v-card-text>
            <v-card-actions>
                <v-btn
                    color="grey darken-5"
                    text
                    @click="cancel"
                >
                    {{$t('cancel')}}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary darken-1"
                    text
                    @click="ok"
                >
                    {{$t('OK')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'AlertDialog',
    props: {
        dialogTitle: String,
        dialogText: String,
        dialog: Boolean
    },
    methods: {
        cancel() {
            this.alertClosed("cancel");
        },
        ok() {
            this.alertClosed("ok");
        },
        alertClosed(action) {
            this.$emit("alert_closed", action);
        }
    }
};
</script>
